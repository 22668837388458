import TokenizedCard from '@/components/TokenizedCard'
import BlackoutLoader from '@/components/shared/BlackoutLoader'
import { http } from '@/services/http'
export default {
  name: 'TokenizedCardList',
  props: {
    cardList: { type: [Object, String] },
    activePoints: null,
    onlyPoints: null,
    currency: null,
  },
  components: {
    TokenizedCard,
    BlackoutLoader
  },
  filters: {
    formatPoints(value) {
      if (typeof value === 'number') {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
    formatCurrency(value) {
      if (typeof value === 'number') {
        const options = {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        return value.toLocaleString(undefined, options);
      }
      return value;
    },
  },
  data () {
    return {
      activeCard: null,
      allowDelete: true,
      cardsList: null,
      urls: null,
      canContinue: false,
      successDelete: false,
      failDelete: false,
      paymentType: null,
      totalInPesos: null,
      totalInPoints: null,
      userPoints: null,
      percentageBar: "#F2F2F2",
      pointsToUse: 0,
      pointsToUseSelected: 0,
      pointToPesosExchangeRate: 0,
      totalPrice: 0,
      maxInput: 0,
      togglePoints: true,
      loadingContinue: false,
      urlsDecidir: {},
      isMobile: window.innerWidth <= 767,
      spinner: false,
      $slider: null,
      $sliderMobile: null,
    }
  },
  methods: {
    handlePaymentTypeChange(event) {
      this.paymentType = event.target.value;
      this.activeCard = null;
      this.clearCartPoints()
    },
    parseData () {
      if (typeof this.cardList === 'string') {
        const list = JSON.parse(this.cardList)
        this.cardsList = list.cards
        this.urls = list.urls
      } else {
        this.cardsList = this.cardList.cards
        this.urls = this.cardList.urls
      }
    },
    activeDelete () {
      if (this.cardsList.length > 0) {
        this.allowDelete = !this.allowDelete
        this.activeCard = null
      }
    },
    toLink () {
      this.spinner = true
      window.scrollTo(0, 0)
      document.body.style.overflow = 'hidden'
      if (this.activeCard >= 0) {
        const myCard = this.cardsList[this.activeCard]
        const link = this.urls.continue + '&alias_process=' + myCard.token.toString() + "&paymentType=" + this.paymentType
        window.location.href = link
      } else if (this.activeCard < 0) {
        window.location.href = this.urls.register + '&paymentType=' + this.paymentType
      }
    },
    // listen event delete
    deleteCardFromList (indexCard, success) {
      if (success) {
        this.successDelete = true
        this.cardsList = this.cardsList.filter(card => card !== this.cardsList[indexCard])
        this.allowDelete = false
      } else if(this.cardsList.length > 3) {
        this.failDelete = true
      }
    },
    pointsOnChange (points) {
      let toUse = parseInt(points ? points : 0)
      if (toUse > this.maxInput) toUse = this.maxInput;

      this.pointsToUse = toUse;
      if (this.$slider) {
        this.$slider.value = toUse;
      }
      if (this.$sliderMobile) {
        this.$sliderMobile.value = toUse;
      }
      const pointsPercentage = (this.pointsToUse / this.totalInPoints) * 100;
      this.percentageBar = `linear-gradient(90deg, #82173E 0%, #F31B22 ${pointsPercentage}%, #F2F2F2 ${pointsPercentage}%)`;

      this.updateValues();
    },
    updateValues () {
      this.pointsToUseSelected = this.pointsToUse;
      const totalProductValueInPesos = this.pointsToUseSelected * this.pointToPesosExchangeRate;
      this.totalPrice = this.totalInPesos - totalProductValueInPesos;
    },
    async updateBack () {
      this.spinner = true
      this.loadingContinue = true;
      const { data } = await http.post("/modules/aperpoint/ajax.php", {
        ajax_point: true,
        action: "get_new_amount",
        total_in_points: this.totalInPoints,
        total_in_pesos: this.totalInPesos,
        points_to_redeem: this.pointsToUseSelected,
        is_shipping_points: false,
        total_points: this.pointsToUseSelected,
      });
      this.loadingContinue = false;
      this.spinner = false
      if (data) {
        const { status, values } = data;
        if (status === "ok") {
          if (this.totalPrice === 0) {
            this.pay()
          } else this.togglePoints = false;
        }
      }
    },
    async clearCartPoints () {
      const { data } = await http.post("/modules/aperpoint/ajax.php", {
        ajax_point: true,
        action: "clear_cart_points",
        total_in_pesos: this.totalInPesos
      })
      this.togglePoints = true
      this.pointsToUse = 0
      this.updateValues()
      this.$slider.value = 0
      this.setSlider()
    },
    pay () {
      this.loadingContinue = false;
      this.spinner = true
      http.post(this.urlsDecidir.action, {
        payment: {
          gateway: 'decidir',
          type: 'points',
        }
      }).then(res => {
        window.location.href = res.data.url
      })
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 767;
    },
    shouldShowButton() {
      return this.pointsToUseSelected > 0 && this.paymentType === 'mix' && this.togglePoints
    },
    payOnlyPoints() {
      this.pointsToUse = this.maxInput;
      this.pointsOnChange(this.pointsToUse);
      this.updateValues();
      this.updateBack();
    },
    setSlider() {
      if (!this.$slider) {
        this.$slider = document.getElementById('slider-point-checkout');
    
        if (this.$slider) {
          this.$slider.addEventListener("change", evt => {
            this.pointsOnChange(evt.detail.value);
          });
        }
      }

      if (!this.$sliderMobile) {
        this.$sliderMobile = document.getElementById('slider-mobile-point-checkout');
    
        if (this.$sliderMobile) {
          this.$sliderMobile.addEventListener("change", evt => {
            this.pointsOnChange(evt.detail.value);
          });
        }
      }
    }
  },
  mounted () {
    this.parseData()
    this.totalInPesos = parseFloat(document.getElementById("total-in-pesos").getAttribute("data-value"))
    this.totalInPoints = parseFloat(document.getElementById("total-in-points").getAttribute("data-value"))
    this.userPoints = parseFloat(document.getElementById("total-user-points").getAttribute("data-value"))
    this.totalPrice = this.totalInPesos
    this.pointToPesosExchangeRate = this.totalInPesos / this.totalInPoints;
    this.maxInput = this.totalInPoints > this.userPoints ? this.userPoints : this.totalInPoints;

    this.urlsDecidir = JSON.parse(document.getElementById("urls").getAttribute("data-value"))
    window.addEventListener('resize', this.handleResize);

    document.addEventListener('vue-update-price', ({detail}) => {
      this.totalPrice = detail.total_price
      this.totalInPesos = detail.total_price
      this.pointToPesosExchangeRate = this.totalInPesos / this.totalInPoints;
      this.pointsOnChange(this.pointsToUse)
      this.updateValues()
    })

    document.addEventListener('vue-update-points', ({detail}) => {
      this.totalInPoints = detail.total_points
      this.pointToPesosExchangeRate = this.totalInPesos / this.totalInPoints;
      this.maxInput = this.totalInPoints > this.userPoints ? this.userPoints : this.totalInPoints;
      this.pointsOnChange(this.pointsToUse)
      this.updateValues()
    })

    this.activePoints = this.activePoints == 1 ? true : false;
    this.onlyPoints = this.onlyPoints == 1 ? true : false;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  updated () {
    this.setSlider()
  }
}
